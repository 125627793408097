import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Grid, TextField, FormControl, Select, MenuItem, InputLabel, Button, Checkbox, FormControlLabel, FormGroup, IconButton } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import TabPanel from '../components/TabPanel';
import Movimenti from './Movimenti';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CancelIcon from '@material-ui/icons/Cancel';

import AddIcon from '@material-ui/icons/Add';
import Ordinare from './Ordinare';
import Ordinato from './Ordinato';
import InUscita from './inUscita';
import moment from 'moment';

class NuovoContratto extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            products: [],
            customer: {
                first_name: "",
                last_name: "",
                email: "",
                address: "",
                city: "",
                province: "",
                postcode: "",
                company_name: "",
                vat_number: "",
                codice_univoco: "",
                phone_number: "",
            },
            destination: "",
            shop: "",
            suppliers: [],
            suppliersChoosen: [],
            allSuppliers: [],
            toBeOrdered: [],
            notes: "",
            handles: [],
            handleSuppliers: [],
            shipping: false,
            mounting: false,
            currentTab: 0,
            total: 0,
            acconti: 0,
            files: [],
            file: null,
            driveDir: null,
            id: null,
            new_contract: false,
        }

    }

    retrieveFiles = async() => {
        let files = await axios.get('https://back.rauccihomedesign.it/api/v1/estimate-file/'  + this.props.location.state.id, {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });

        this.setState({files: files.data.message});
    }

    retrieveGoogleDriveDir = async() => {
        let dir = await axios.get('https://back.rauccihomedesign.it/api/v1/drive/dir/'  + this.props.location.state.id, {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });
        if(dir.data.message){
            this.setState({
                driveDir: 'https://drive.google.com/drive/u/0/folders/' + dir.data.message.id
            })
        }
    }

    componentDidMount = async() => {
        this.setState({
            new_contract: this.props.location.state.new_contract
        });

        await this.retrieveEstimate();
        await this.getSuppliers();
        await this.retrieveHandleSuppliers();
        await this.retrieveGoogleDriveDir();

        await this.retrieveFiles();


        let orderMovements = await axios.get('https://back.rauccihomedesign.it/api/v1/order-movements/' + this.props.location.state.id, {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });

        const sommaAnticipi = orderMovements.data.filter(x => !x.isExpense).map(x => x.price).reduce((acc, curr, array) => acc + curr, 0);
        
        let total = 0; 
        for(let product of this.state.products){
            total += product.price * product.quantity;
        }

        this.setState({total: total, acconti: sommaAnticipi});

    }
    retrieveHandleSuppliers = async() => {
        const res = await axios.get('https://back.rauccihomedesign.it/api/v1/handles-suppliers', {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });

        this.setState({
            handleSuppliers: res.data
        });
    }
    retrieveEstimate = async() => {
        const id = this.props.location.state ? this.props.location.state.id : null;
        if(id){
            const estimate = await axios.get('https://back.rauccihomedesign.it/api/v1/estimates/' + id +'?status=' + 0,
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            }
            );
            const customer = {
                first_name: estimate.data.name,
                last_name: estimate.data.surname,
                email: estimate.data.email,
                address: estimate.data.address,
                city: estimate.data.city,
                province: estimate.data.province,
                postcode: estimate.data.postcode,
                company_name: estimate.data.company_name,
                vat_number: estimate.data.vat_number,
                codice_univoco: estimate.data.codice_univoco,
                phone_number: estimate.data.phone_number,
                id: estimate.data.customer_id,
            };

            this.setState({
                products: estimate.data.products.map(x => ({...x, shipped: !x.shipped})),
                customer: customer,
                shop: estimate.data.shop,
                handles: estimate.data.handles,
                shipping: estimate.data.shipping,
                mounting: estimate.data.mounting,
                destination: estimate.data.destination,
                created_at: estimate.data.creation_date
            });
        }
    }
    setCustomerData = (key, value) => {
        let customerData = this.state.customer;
        customerData[key] = value;
        this.setState({customer: customerData});
    }
    addRowHandles = () => {
        const handles = this.state.handles;
        handles.push({});

        this.setState({handles: handles});
    }

    setProductData = async (index, value, key) => {
        let productsData = this.state.products;
        productsData[index][key] = value;

        if(key === 'supplier'){
            const supplier = this.state.suppliers.filter(x => x.id === this.state.products.supplier)[0];
            let suppliers = [];
            let _toMerge = this.state.allSuppliers;

            for(let _product of this.state.products){
                //const _supplier = this.state.suppliers.filter(x => x.id === _product.supplier)[0];
                //if(!suppliers.filter(x => x.name === _supplier.name)[0])suppliers.push({name: _supplier.name, description: ""});
            }

           /* for(let idx in suppliers){
                const supplier = suppliers[idx];
                
                let isHere = _toMerge.filter(x => x.name === supplier.name).length !== 0;

                if(!isHere){
                    _toMerge.push(supplier);
                }

            }*/

            await this.setState({allSuppliers: suppliers});

        }
       
        this.setState({products: productsData});
    }   
    setHandleData = (index, value, key) => {
        let handleData = this.state.handles;
        handleData[index][key] = value;
        this.setState({handles: handleData});
    }   

    base64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => console.log(reader.error);
    });

    deleteRow = (index) => {
        let cachedArray = this.state.products;
        let newArray = cachedArray.filter((o, i) => i !== index);
        this.setState({
            products: newArray
        });
    }

    getSuppliers = async() => {
        const suppliers = await axios.get('https://back.rauccihomedesign.it/api/v1/suppliers', 
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            }
        );

        if(suppliers){
            this.setState({
                suppliers: suppliers.data
            });
        }
    }
    deleteRow = (index) => {
        let cachedArray = this.state.products;
        let newArray = cachedArray.filter((o, i) => i !== index);
        this.setState({
            products: newArray
        });
    }
    setSupplierForRow = async(value, index) => {
        let suppliersData = this.state.suppliersChoosen;
        suppliersData[index] = value;
        await this.setState({suppliersChoosen: suppliersData});

    }
    setToBeOrdered = async(value, index) => {
        let ToBeOrdered = this.state.toBeOrdered;
        ToBeOrdered[index] = value;
        this.setState({toBeOrdered: ToBeOrdered});
    }

    createContract = async(stay = false) => {

        const customerResponse = await axios.put('https://back.rauccihomedesign.it/api/v1/customers/' + this.state.customer.id, this.state.customer,
        {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });

        let response;

        if(this.state.new_contract){
            response = await axios.post('https://back.rauccihomedesign.it/api/v1/estimates',
            {
                customer_id: this.state.customer.id,
                store: this.state.shop,
                products: this.state.products,
                handles: this.state.handles,
                shipping: this.state.shipping,
                mounting: this.state.mounting,
                destination: this.state.destination,
                notes: this.state.notes,
                status: 1
            },
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            });

            this.setState({
                id: response.data.message.id,
                new_contract: false
            });
        }else{
            const id = this.state.id ? this.state.id : this.props.location.state.id;
            response = await axios.put('https://back.rauccihomedesign.it/api/v1/estimates/' + id,
            {
                store: this.state.shop,
                products: this.state.products,
                handles: this.state.handles,
                shipping: this.state.shipping,
                mounting: this.state.mounting,
                destination: this.state.destination,
                notes: this.state.notes,
                status: 1
            },
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            });
        }
        

        if(response.status === 200){
            if(!stay){
                this.props.history.push('/contratti');
            }else{
                alert("Contratto modificato");
            }
            
        }

    }

    a11yProps = (index) => {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    uploadFile = async (file) => {
        let fileBase = await this.base64(file);

        fileBase = fileBase.split(',')[1];
        const id = this.props.location.state ? this.props.location.state.id : null;

        const newFile = {
            file: fileBase,
            name: file.name,
            estimate: id
        };

        let res = await axios.post('https://back.rauccihomedesign.it/api/v1/estimate-file', newFile, {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });

        let files = this.state.files;

        if(res.status === 200) { files.push(newFile); await this.retrieveFiles()}
    }
    addRow = () => {
        const products = this.state.products;
        products.push({});

        this.setState({products: products});

        window.setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);    
        },
            100)

    }
    render(){
        return (
            <div>
                <h1>Inserisci nuovo contratto</h1>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <Button variant="contained" color="primary" onClick={() => this.createContract(false)} style={{marginTop: 32, marginBottom: 32}}>
                            Salva Contratto
                        </Button>
                    </Grid>
                </Grid>
                {/* <TableContainer component={Paper} style={{width: '85%'}}>
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Totale Imponibile</TableCell>
            <TableCell align="left">Residuo</TableCell>
            <TableCell align="right">IVA 4%</TableCell>
            <TableCell align="right">IVA 10%</TableCell>
            <TableCell align="right">IVA 22%</TableCell>
            <TableCell align="right">Totale Lordo</TableCell>
            <TableCell align="right">Acconti</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow >
              
              <TableCell align="left">{(this.state.total / 100).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})}</TableCell>
              <TableCell align="left">{((this.state.total / 100) + (this.state.products.filter(x => x.iva == 4).reduce((a, b) => a + (b.price * b.quantity || 0), 0) * 0.0004) + (this.state.products.filter(x => x.iva == 10).reduce((a, b) => a + (b.price * b.quantity || 0), 0) * 0.001) + (this.state.products.filter(x => x.iva == 22).reduce((a, b) => a + (b.price * b.quantity || 0), 0) * 0.0022) - (this.state.acconti / 100)).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})}</TableCell>
              <TableCell align="right">{(this.state.products.filter(x => x.iva == 4).reduce((a, b) => a + (b.price * b.quantity || 0), 0) * 0.0004).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})}</TableCell>
              <TableCell align="right">{(this.state.products.filter(x => x.iva == 10).reduce((a, b) => a + (b.price * b.quantity || 0), 0) * 0.001).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})}</TableCell>
              <TableCell align="right">{(this.state.products.filter(x => x.iva == 22).reduce((a, b) => a + (b.price * b.quantity || 0), 0) * 0.0022).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})}</TableCell>
              <TableCell align="right">{((this.state.total / 100) + (this.state.products.filter(x => x.iva == 4).reduce((a, b) => a + (b.price * b.quantity || 0), 0) * 0.0004) + (this.state.products.filter(x => x.iva == 10).reduce((a, b) => a + (b.price * b.quantity || 0), 0) * 0.001) + (this.state.products.filter(x => x.iva == 22).reduce((a, b) => a + (b.price * b.quantity || 0), 0) * 0.0022)).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})}</TableCell>
              <TableCell align="right">{(this.state.acconti / 100).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer> */}
                <AppBar position="static" style={{marginTop: 64}}>
                    <Tabs value={this.state.currentTab} onChange={(value, newValue) => this.setState({currentTab: newValue})} aria-label="simple tabs example">
                        <Tab label="Contratto" {...this.a11yProps(0)} />
                        <Tab label="Operai" {...this.a11yProps(1)} />
                        <Tab label="Acconti" {...this.a11yProps(2)} />
                        <Tab label="File" {...this.a11yProps(3)}></Tab>
                        <Tab label="Merce da ordinare" {...this.a11yProps(4)}></Tab>
                        <Tab label="Merce ordinata" {...this.a11yProps(5)}></Tab>
                        <Tab label="Merce in uscita" {...this.a11yProps(6)}></Tab>
                    </Tabs>
                </AppBar>
                <TabPanel value={this.state.currentTab} index={0}>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <TextField label="Nome" value={this.state.customer.first_name} onInput={(e) => this.setCustomerData('first_name', e.target.value)}></TextField>
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField label="Cognome" value={this.state.customer.last_name} onInput={(e) => this.setCustomerData('last_name', e.target.value)}></TextField>
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField label="E-mail" value={this.state.customer.email} onInput={(e) => this.setCustomerData('email', e.target.value)}></TextField>
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField label="Numero di Telefono" value={this.state.customer.phone_number} onInput={(e) => this.setCustomerData('phone_number', e.target.value)}></TextField>
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField label="Data contratto" value={moment(this.state.created_at).format('DD/MM/YYYY')} disabled></TextField>
                            </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField label="Indirizzo" value={this.state.customer.address} onInput={(e) => this.setCustomerData('address', e.target.value)}></TextField>
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField label="Città" value={this.state.customer.city} onInput={(e) => this.setCustomerData('city', e.target.value)}></TextField>
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField label="Provincia" value={this.state.customer.province} onInput={(e) => this.setCustomerData('province', e.target.value)}></TextField> 
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField label="Codice postale" value={this.state.customer.postcode} onInput={(e) => this.setCustomerData('postcode', e.target.value)}></TextField>
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField label="Destinazione" value={this.state.destination} onInput={(e) => this.setState({destination: e.target.value})}></TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField label="Ragione sociale" value={this.state.customer.company_name} onInput={(e) => this.setCustomerData('company_name', e.target.value)}></TextField> 
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField label="P.IVA / Codice Fiscale" value={this.state.customer.vat_number} onInput={(e) => this.setCustomerData('vat_number', e.target.value)}></TextField>
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField label="Codice Univoco" value={this.state.customer.codice_univoco} onInput={(e) => this.setCustomerData('codice_univoco', e.target.value)}></TextField>
                        </FormControl>
                      
                        <FormControl fullWidth>
                            
                            <InputLabel id="demo-simple-select-helper-label">Negozio</InputLabel>
                            <Select label="Negozio" value={this.state.shop} onChange={(e) => this.setState({shop: e.target.value})}>
                                <MenuItem value="Santa Maria Capua Vetere" >Santa Maria Capua Vetere</MenuItem>
                                <MenuItem value="Marcianise">Marcianise</MenuItem>
                            </Select>
                        </FormControl>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.shipping}
                                        onChange={() => this.setState({shipping: !this.state.shipping})}
                                        name="shipping"
                                        color="primary"
                                    />
                                }
                                label="Trasporto incluso"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.mounting}
                                        onChange={() => this.setState({mounting: !this.state.mounting})}
                                        name="shipping"
                                        color="primary"
                                    />
                                }
                                label="Posa in opera"
                            />
                        </FormGroup>

                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                                        <Grid item xs={2}>
                                            <Button variant="contained" color="primary" onClick={() => this.createContract(false)} style={{marginTop: 32, marginBottom: 32}}>
                                                Salva Contratto
                                            </Button>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button variant="contained" color="primary" onClick={() => this.createContract(true)} style={{marginTop: 32, marginBottom: 32}}>
                                                Aggiorna Contratto
                                            </Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button variant="contained" color="primary" onClick={() => window.open("https://back.rauccihomedesign.it/contract-pdf/" + this.props.location.state.id)} style={{marginTop: 32, marginBottom: 32}}>
                                                Stampa contratto
                                            </Button>
                                        </Grid>
                                        
                        </Grid>
                        <h2>Prodotti</h2>
                        <Button variant="contained" color="primary" onClick={() => this.addRow()}>
                                                Aggiungi Prodotto
                        </Button>
                        {this.state.products.map((row, index) => 
                            <Grid container spacing={2} style={{paddingTop: 24}}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth >
                                        <TextField multiline rowsMax={4} label="Descrizione prodotto" value={this.state.products[index].name} onChange={(e) => this.setProductData(index, e.target.value, "name")}></TextField>
                                    </FormControl>
                                    
                                </Grid>
                                <Grid item xs={1}>
                                    <FormControl >
                                        <TextField label="Prezzo" type="number" value={this.state.products[index].price / 100} onChange={(e) => this.setProductData(index, parseInt(parseFloat(e.target.value) * 100), "price")}></TextField>
                                    </FormControl>
                                    
                                </Grid>
                                <Grid item xs={1}>
                                    <FormControl>
                                        <TextField label="Quantità" type="number" value={this.state.products[index].quantity} onChange={(e) => this.setProductData(index, e.target.value, "quantity")}></TextField>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <FormControl>
                                        <TextField label="Subtotale" type="number" value={this.state.products[index].quantity * this.state.products[index].price / 100} disabled></TextField>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <FormControl fullWidth>
                                        <TextField label="IVA" type="number" value={this.state.products[index].iva} onChange={(e) => this.setProductData(index, e.target.value, "iva")}></TextField>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <FormControl>
                                        <InputLabel id="demo-simple-select-helper-label">Fornitore</InputLabel>
                                        <Select label="Fornitore" value={this.state.products[index].supplier} onChange={(e) => this.setProductData(index, e.target.value, "supplier")}>
                                        <MenuItem value={null}>Nessuno</MenuItem>
                                        {
                                            this.state.suppliers.sort((a, b) => {
                                                var textA = a.name.toUpperCase();
                                                var textB = b.name.toUpperCase();
                                                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                            }).map((x, index) => <MenuItem value={x.id}>{x.name}</MenuItem>)
                                        }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <FormControl fullWidth>
                                        <TextField multiline maxRows={3} label="Note" type="text" onChange={(e) => this.setProductData(index, e.target.value, "notes")} value={this.state.products[index].notes}></TextField>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <FormControl fullWidth>
                                        <FormControlLabel control={
                                            <Checkbox
                                                name="toBeOrdered"
                                                value={this.state.products[index].shipped}
                                                defaultChecked={this.state.products[index].shipped}
                                                onChange={(e) => this.setProductData(index, e.target.checked, "shipped")}
                                                />
                                        }
                                        style={{marginTop: 12}}
                                        label="Da ordinare" />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={() => this.deleteRow(index)} style={{marginTop: 8}}>
                                            <CancelIcon htmlColor="#ff0000"/>
                                    </IconButton>
                                </Grid>
                            </Grid>    
                        )}
                        <Button variant="contained" color="primary" onClick={() => this.addRow()}>
                            Aggiungi Prodotto
                        </Button>
                    </Grid>
                </Grid>

                </TabPanel>
                <TabPanel value={this.state.currentTab} index={1}>
                    <Movimenti wrapper={true} showExpenses={true} workers expense></Movimenti>
                </TabPanel>
                <TabPanel value={this.state.currentTab} index={2}>
                    <Movimenti wrapper={true} showExpenses={true} expense={false}></Movimenti>
                </TabPanel>

                <TabPanel value={this.state.currentTab} index={3} style={{marginLeft: 8, marginRight: 8}}>
                    <h2>Files</h2>
                       
                        <Button variant="contained" color="primary" onClick={() => window.open(this.state.driveDir)} style={{marginTop: 32, marginBottom: 32}}>
                            Apri Google Drive
                        </Button>
                        <TableContainer style={{marginTop: 32, marginBottom: 32, wdith: 400}} component={Paper}>
                            <Table  aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <TableCell>Nome file</TableCell>
                                    <TableCell align="right">Link</TableCell>
                                
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.state.files.map(row => 
                                            <TableRow >
                                    
                                            <TableCell component="th" scope="row">{row.basename}</TableCell>
                                            <TableCell align="right"><Button variant="contained" color="primary" onClick={() => window.open('https://docs.google.com/spreadsheets/d/' + row.id + '/edit')}>Modifica file</Button></TableCell>
                                            
                                        </TableRow>
                                        )
                                    }
                                
                                </TableBody>
                            </Table>
                        </TableContainer>
                </TabPanel>
                <TabPanel value={this.state.currentTab} index={4} style={{marginLeft: 8, marginRight: 8}}>
                    <div style={{padding: '1rem'}}><Ordinare></Ordinare></div>
                </TabPanel>
                <TabPanel value={this.state.currentTab} index={5} style={{marginLeft: 8, marginRight: 8}}>
                    <div style={{padding: '1rem'}}><Ordinato></Ordinato></div>
                </TabPanel>
                <TabPanel value={this.state.currentTab} index={6} style={{marginLeft: 8, marginRight: 8}}>
                    <div style={{padding: '1rem'}}><InUscita></InUscita></div>
                </TabPanel>
            </div>
        )
    }
   
}

export default withRouter(NuovoContratto);
