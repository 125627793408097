import { Grid, TextField, FormControl, Select, MenuItem, InputLabel, Button } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NuovoFornitore extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            name: "",
            company: "",
            vatNumber: "",
            open: false,
            status: 'success',
            message: ""
        }
    }

    addSupplier = async () => {
        let response = await axios.post('https://back.rauccihomedesign.it/api/v1/suppliers',
            {
                name: this.state.name,
                company_name: this.state.company,
                vat_number: this.state.vatNumber
            },
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            }
        );
        
        if(response.status === 200){
            this.setState({
                open: true,
                status: 'success',
                message: response.data.message
            });
        }else{
            this.setState({
                open: true,
                status: 'success',
                message: response.data.message
            });
        }
        
        this.props.history.push('fornitori');
    }
    render(){
        return (
            <div>
                <h1>Inserisci nuovo fornitore</h1>
                <Grid container spacing={3}>

                    <Grid item xs={6}>
                        <h2>Dati Fornitore</h2>
                        <FormControl fullWidth>
                            <TextField label="Nome" value={this.state.name} onInput={(e) => this.setState({name: e.target.value})}></TextField>
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField label="Ragione sociale" value={this.state.company} onInput={(e) => this.setState({company: e.target.value})}></TextField> 
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField label="P.IVA / Codice Fiscale" value={this.state.vatNumber} onInput={(e) => this.setState({vatNumber: e.target.value})}></TextField>
                        </FormControl>

                    </Grid>


                    
                </Grid>
                <Button variant="contained" color="primary" onClick={this.addSupplier} style={{marginTop: 32}}>
                        Aggiungi Fornitore
                </Button>

                <Snackbar open={this.state.open} autoHideDuration={6000} onClose={() => this.setState({open: false})}>
                    <Alert onClose={() => this.setState({open: false})} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default withRouter(NuovoFornitore);