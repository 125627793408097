import Router from "./routes/Router";
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import PropTypes from 'prop-types';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));



class App extends React.Component {
  
  constructor(props){
    super(props);

    this.state = {
      open: true,
      complexLayout: false,
      alreadyOpen: false
    }

    // axios.interceptors.request.use((req) => {
    //   toastId = toast("Caricamento in corso", {
    //     icon: "⏱",
    //     duration: '1000000',
    //     id: 'loading'
    //   });

    //   return req;
    // }, (error) => {
    //   return Promise.reject(error);
    // });

    axios.interceptors.response.use((res) => {
      toast.dismiss();
      if((res.config.method === 'put' && typeof res.data === 'string') || res.status === 500){
        console.log(typeof res.data);
        
        toast.error("Errore dal server", {id: 'error'});
      }else if(res.config.method === 'put' || res.config.method === 'post'){
        toast.success("Richiesta inserita con successo.", {id: 'success'});
      }

      return res;
    })


  }

  componentDidMount = () => {
  }

  handleDrawerOpen = () => {
    this.setState({open: true});
  };
  handleDrawerClose = () => {
    this.setState({open: false});
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.location !== prevProps.location) {
      console.log('locationChanged')
    }
  }
  render(){
    let classes = this.props.classes;
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    return (
      <div>
  
        <Router forceRefresh={true}></Router>
        <Toaster />
      </div>
    
   
  );
  }
  
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(useStyles)(App);