import { Grid, TextField, FormControl, Select, MenuItem, InputLabel, Button } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class CaricaTracciato extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            price: null,
            file: null
        }
    }

    base64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(this.state.file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => console.log(reader.error);
    });
    addSupplier = async () => {
       
        let file = await this.base64(this.state.file);
        file = file.split(',')[1];
        

        let res = await axios.put('https://back.rauccihomedesign.it/api/v1/suppliers/' + this.props.location.state.id, {file: file, name: this.state.file.name }, {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}});

        /*let responseWallet = await axios.post('https://back.rauccihomedesign.it/api/v1/contabilities', {
                causale: this.state.causale,
                isExpense: false,
                price: this.state.price,
                password: this.props.location.password
            },
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            }
        );

        this.props.history.push({pathname: '/portafoglio'});*/

    }

    componentDidMount = async() => {
        

    }
    render(){
        return (
            <div>
                <h1>Inserisci nuovo tracciato</h1>
                <Grid container spacing={3}>

                    <Grid item xs={6}>
                    <input
    onChange={(e) => this.setState({file: e.target.files[0]})}
    style={{ display: "none" }}
    id="contained-button-file"
    type="file"
  />
                        <label htmlFor="contained-button-file">
    <Button variant="contained" color="primary" component="span">
      Upload
    </Button>
  </label>
                    </Grid>


                    
                </Grid>
                <Button variant="contained" color="primary" onClick={this.addSupplier} style={{marginTop: 32}}>
                        Aggiungi Tracciato
                </Button>

               
            </div>
        )
    }
}

export default withRouter(CaricaTracciato);