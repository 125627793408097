import React, {forwardRef} from 'react';
import Button from '@material-ui/core/Button';

import { withRouter } from 'react-router-dom';
import axios from 'axios';
import MaterialTable from '@material-table/core';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

class DaOrdinare extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            merce: []
        }
    }

    componentDidMount = async() => {
        let products = await axios.get('https://back.rauccihomedesign.it/api/v1/to-be-delivered', {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });

        //console.log(products.data.reduce((acc, curr) => acc[curr.estimates_id] ? acc[curr.estimates_id].push(curr) : acc[curr.estimates_id] = [curr], []));

        //acc[curr.]
        this.setState({
            merce: Object.values(products.data)
        });

    }

    goToSuppliers = (data) => {
        if(data.suppliers.includes(null)) alert("Specificare il fornitore");
        else this.props.history.push({pathname: 'da-ordinare', state: { id: data.id }});
    }
    render(){
        return(
            <>

                <MaterialTable title="Elenco Merce da Ordinare" icons={tableIcons} data={this.state.merce}
                 options={{
                    paging:true,
                    pageSize:50,       // make initial page size
                    emptyRowsWhenPaging: false,   // To avoid of having empty rows
                    pageSizeOptions:[50,100,150,200],    // rows selection options
                  }}
                columns={[
                    {
                        title: 'Contratto',
                        field: 'causale',
                        render: data => <p onClick={() => this.props.history.push({pathname: 'nuovo-contratto', state: { id: data.id }})}>Contratto {data.customer.name} {data.customer.surname} n° {data.id}</p>,
                        customFilterAndSearch: (term, data) => (data.customer.first_name.toLowerCase().includes(term.toLowerCase()) || data.customer.Addsurname.toLowerCase().includes(term.toLowerCase()))

                    },
                    {
                        title: 'Fornitori',
                        field: 'suppliers',
                        render: data => (data.suppliers.some(x => x === null) ? <div><p style={{color: 'red'}}>Devi selezionare il fornitore,</p> <p> {data.suppliers.join(", ")}</p></div>: <p>{data.suppliers.join(", ")}</p>)
                    },
                    {
                        title: 'Azioni',
                        field: 'actions',
                        render: data => <Button title="Vai all'ordine" variant="contained" color="primary" onClick={() => this.goToSuppliers(data)}>Vai all'ordine</Button>
                    }
                ]}></MaterialTable>
            </>
        )
    }
}

export default withRouter(DaOrdinare);
