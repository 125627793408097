import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import DescriptionIcon from '@mui/icons-material/Description';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import NotificationsIcon from '@material-ui/icons/Notifications';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PostAddIcon from '@material-ui/icons/PostAdd';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import PeopleIcon from '@material-ui/icons/People';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';

import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import Archive from '@material-ui/icons/Archive';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ForkLeft from '@mui/icons-material/Toys';

import { withRouter } from 'react-router';
import App from '../App';
import LocalShipping from '@material-ui/icons/LocalShipping';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: 100
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));



class Layout extends React.Component{
    constructor(props){
        super(props);
        this.state = {
          open: false
        }
    }

    
    render(){
        const classes = this.props.classes;
        return (
            <div className={classes.root}><CssBaseline /><AppBar position="absolute" className={clsx(classes.appBar, this.open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          
         
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
        }}
        open={this.state.open}
      >

        <div className={classes.toolbarIcon}>

          <IconButton onClick={() => this.setState({open: !this.state.open})}>
            {this.state.open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>

        </div>
        {this.state.open ? <img src="https://assistendo-images.s3.eu-south-1.amazonaws.com/black.png" style={{ height:100, objectFit: 'contain', padding: 10}}/> : null }
        <ListItem button onClick={() => {this.props.history.push('preventivi'); window.location.reload()}}>
            <ListItemIcon>
              <PostAddIcon />
            </ListItemIcon>
            {this.state.open ? <ListItemText primary="Preventivi" /> : null}
        </ListItem>
        <ListItem button onClick={() => this.props.history.push('contratti')}>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            {this.state.open ? <ListItemText primary="Contratti" /> : null}
        </ListItem>
        <ListItem button onClick={() => this.props.history.push('fornitori')}>
            <ListItemIcon>
              <LocalShippingIcon />
            </ListItemIcon>
            {this.state.open ? <ListItemText primary="Fornitori" /> : null}

        </ListItem>

        <ListItem button onClick={() => this.props.history.push('da-ordinare-lista')}>
          <ListItemIcon>
            <Archive />
          </ListItemIcon>

          {this.state.open ? <ListItemText primary="Da ordinare" /> : null}
        </ListItem>
        <ListItem button onClick={() => this.props.history.push('clienti')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            {this.state.open ? <ListItemText primary="Clienti" /> : null}
        </ListItem>
        <ListItem button onClick={() => this.props.history.push('maniglie')}>
            <ListItemIcon>
              <MeetingRoomIcon />
            </ListItemIcon>
            {this.state.open ? <ListItemText primary="Maniglie" /> : null}
        </ListItem>
        <Divider />
        <ListItem button onClick={() => this.props.history.push('operai')}>
            <ListItemIcon>
              <EmojiPeopleIcon />
            </ListItemIcon>
            {this.state.open ? <ListItemText primary="Operai" />: null}
        </ListItem>
        {/* <ListItem button onClick={() => this.props.history.push('portafoglio')}>
            <ListItemIcon>
              <AccountBalanceWalletIcon />
            </ListItemIcon>
            {this.state.open ? <ListItemText primary="Portafoglio" /> : null}
        </ListItem> */}
{/* 
        <ListItem button onClick={() => this.props.history.push('dashboard')}>
            <ListItemIcon>
              <AnalyticsIcon />
            </ListItemIcon>
            {this.state.open ? <ListItemText primary="Report" /> : null}
        </ListItem> */}
        <ListItem button onClick={() => this.props.history.push('muletto')}>
            <ListItemIcon>
              <ForkLeft />
            </ListItemIcon>
            {this.state.open ? <ListItemText primary="Muletto" /> : null}
        </ListItem>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container} style={{marginTop: 100, marginRight: 0, marginLeft: 100, maxWidth: '89vw'}}>
            {this.props.child}
        </Container>
      </main></div>
        )
    }
}

export default withStyles(useStyles)(withRouter(Layout));